import React, { useState } from 'react'
import { Button } from 'antd'
import { useAutheDataContext } from '../../contexts/AuthDataContext';
import { UserModals } from '../main/UserModals';

const WHITEPAPER_LINK_2023 = "https://drive.google.com/file/d/1M1l4Qqg4BbwDWK0PNCVFS_XBsPOdGOnX"
const WHITEPAPER_LINK_2024 = "https://drive.google.com/file/d/1Klw-pra8UTQfdb0bHzSlObpGTWn-zI7Z"

interface BtnProps {
    text: string
}
export const PaperDownloadButton: React.FC<BtnProps> = ({text}) => {
    const {user} = useAutheDataContext()
    const [openLogin, setOpenLogin] = useState(false)
    const [openSignUp, setOpenSignUp] = useState(false)
    const [openForgotPassword, setOpenForgotPassword] = useState(false)

    const downloadPaper = (e: any) => {
        e.preventDefault();
        if (user === null)  {
            setOpenLogin(true)
        } else {
            if (text == 'Free Download') {
            window.open(WHITEPAPER_LINK_2024)
            } else {
                window.open(WHITEPAPER_LINK_2023)
            }
        }
    }
    return  (
        <div>
                <Button 
                    type="primary" 
                    className='whitepaper-download-btn'> 
                    <span key='download-whitepaper' 
                            onClick={(e) => downloadPaper(e)}>
                        {text}
                    </span>
                </Button>                       
                <UserModals openLogin={openLogin}
                            setOpenLogin={setOpenLogin}
                            openSignUp={openSignUp}
                            setOpenSignUp={setOpenSignUp}
                            openForgotPassword={openForgotPassword}
                            setOpenForgotPassword={setOpenForgotPassword}/>    
        </div>
        
    )
}