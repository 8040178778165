import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Row,
  Col,
  Layout,
  Tag,
  Modal,
  Tooltip,
  Collapse,
  Space,
  Card,
  message
} from "antd";
import { Spin } from "antd"
import 'font-awesome/css/font-awesome.min.css';

import NewsHubFooter from "../main/NewsHubFooter";
import NewsHubHeader from "../main/NewsHubHeader";
import TagTooltip from "./TagTooltip";
import { useParams } from "react-router-dom";
import { getMainContentSectionMap, getOppThemeColor } from "../../utils/utils";
import "../../css/Common.css";
import { useAutheDataContext } from "../../contexts/AuthDataContext";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { useNavigate } from "react-router-dom";

import api from "../../api/api";
import { DATE_FORMAT, API_URL } from "../../utils/utils";
import { CatDict, CountryContentType } from "../../types";
import { getFirstPart, mapData } from "../../utils/utils";
import CardSection from "./CardSection";
import { CARD_PER_PAGE, gridInfo } from "./configs";
import NewsPagination from "./NewsPagination";
import SearchBox from "./SearchBox";
import { useTheme } from "../../contexts/ThemeContext";
import { CardDetails } from "./CardDetails";
import MetaTags from "../contents/MetaTags"
import ShareRow from "../contents/ShareRow"
import { getShareableText, getShareableArticleUrl, handleCopyLink } from '../../utils/share'
const { Panel } = Collapse;
const { Content } = Layout;
const mainContentSectionMap = getMainContentSectionMap();

const NewsContentArticle: React.FC<CountryContentType> = ({ subscribed }) => {
  const navigate = useNavigate();
  let { id } = useParams();

  const { authToken } = useAutheDataContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [articleData, setArticleData] = useState<any>(null);
  const [showBiasDetection, setShowBiasDetection] = useState(false);

  const toggleBiasDetection = () => {
    setShowBiasDetection(!showBiasDetection);
  };

  const { theme } = useTheme();

  let headers = authToken
    ? {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    : {};

  const fetchData = async (queryStr: string, headers: any) => {
    try {
      setLoading(true);
      setArticleData(null);

      let res = await api.get(queryStr, headers);
      setArticleData(res.data);
    } catch (err) {
      console.log("err:", err);
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

useEffect(() => {
  const onPageLoad = () => {
    let queryStr = `${API_URL}/contents/articles/${id}/`;
    let headers = {};
    fetchData(queryStr, headers);
    console.log("Page resources are fully loaded.");
  };

  // Check if the page is already loaded
  if (document.readyState === "complete") {
    onPageLoad();
  } else {
    // Add event listener for when page is fully loaded
    window.addEventListener("load", onPageLoad);
    return () => window.removeEventListener("load", onPageLoad);
  }
}, []);

  useEffect(() => {
    if (!loading && articleData) {
      console.log("All content has been loaded and rendered.");
      // You can trigger further actions here if needed
    }
  }, [loading, articleData]);



  return (
    <Layout
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme,
      }}
    >

      {articleData && articleData.title && (
        <MetaTags
          title={getShareableText(articleData.title)}
          description={articleData.summary}
          imageUrl={articleData.image_url || "/static/media/newshub-logo-main.96d1d3ed958d2a047f63.png"}
          url={getShareableArticleUrl(articleData.id)}
          keywords={`AI, Machine Learning, ${articleData.title}, AI NewsHub, AI News`}
        />
      )}

      <NewsHubHeader />
      <Content style={{ flex: 1 }}>
      <Row
          justify="center"
          align="middle"
          gutter={[16, 16]}
          style={{ marginTop: "50px", marginBottom: "50px" }}
        >
          <Col xs={20} sm={20} md={20} lg={20} xl={16}>
            <Spin spinning={loading}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              </div>
            </Spin>
            {articleData && (
              <Card
                cover={<img alt={articleData.title} src={articleData.image_url} />}
              >
                <h1 style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                  {articleData.title}
                </h1>
                <Row style={{ paddingBottom: "0.5rem" }}>
                  <strong>AI Generated Summary</strong>
                </Row>
                <p>
                  {articleData.metadata
                    ? articleData.metadata.llm__summary__tags
                      ? articleData.metadata.llm__summary__tags
                      : articleData.summary
                    : articleData.summary}
                </p>
                {articleData.metadata && (
                  <>
                    <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
                      {articleData.metadata.llm__bias_detection__tags && (
                        <Col flex={1}>
                          <Collapse
                            activeKey={showBiasDetection ? "1" : "0"}
                            size="small"
                          >
                            <Panel
                              header={
                                <div>
                                  <div
                                    onClick={toggleBiasDetection}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "left",
                                    }}
                                  >
                                    <strong
                                      style={{ marginRight: 8, color: "black" }}
                                    >
                                      Article Bias Detection
                                    </strong>
                                  </div>
                                </div>
                              }
                              key="1"
                            >
                              <p>
                                {articleData.metadata.llm__bias_detection__tags}
                              </p>
                            </Panel>
                          </Collapse>
                        </Col>
                      )}
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
                      {articleData.metadata.llm__sentiment__analysis__tags && (
                        <Col flex={1}>
                          <strong>Sentiment: </strong>
                          <span>
                            {
                              articleData.metadata
                                .llm__sentiment__analysis__tags
                            }
                          </span>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
                <Row gutter={[16, 16]}>
                  <Col flex={1}>
                    {articleData.topics.length > 0 && (
                      <TagTooltip
                        name={articleData.topics[0].name}
                        desc="Topic"
                        color="#03045e"
                      />
                    )}
                    {articleData.industry_sectors.length > 0 && (
                      <TagTooltip
                        name={articleData.industry_sectors[0].name}
                        desc="Industry Sector"
                        color="#0077b6"
                      />
                    )}
                    {articleData.publisher.categories.length > 0 && (
                      <TagTooltip
                        name={articleData.publisher.categories[0].name}
                        desc="Publisher Category"
                        color="#00b4d8"
                      />
                    )}
                  </Col>
                  <Col flex={0}>
                    <Space>
                      <Button
                        type="primary"
                        href={`${articleData.external_url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read full article
                      </Button>
                    </Space>
                  </Col>
                </Row>
                <ShareRow
                shareUrl={getShareableArticleUrl(articleData.id)}
                shareText={getShareableText(articleData.title)}
                />
              </Card>
            )}
          </Col>
        </Row>
      </Content>
      <NewsHubFooter />
    </Layout>
  );
};

export default NewsContentArticle;
