import React, { useEffect, useState } from 'react';
import { WordCloud } from '@ant-design/charts';
import { Spin } from 'antd';
import { SearchCriteria } from '../../../types';
import {Dayjs} from 'dayjs'
import { useAutheDataContext } from '../../../contexts/AuthDataContext';
import { fetchWordCloud } from '../dataFetcher';

export interface WordBubbleChartType {
  handleSearch: (options: SearchCriteria) => void,
  startDate: Dayjs, 
  endDate: Dayjs
}

const WordCloudChart: React.FC<WordBubbleChartType> = ({ handleSearch, 
                                                         startDate, 
                                                         endDate}) => {
  const [wordCloud, setWordCloud] = useState<any>([])  
  const {authToken} = useAutheDataContext()
  const [loading, setLoading] = useState<any>(false)
  
  const config: any = {
    data: wordCloud,
    wordField: 'display',
    weightField: 'value',
    colorField: 'name',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [14, 50],
      rotation: 0,
    },
    random: () => 0.5,
    onReady: (plot: any) => {
      plot.on('element:click', (e: any) => {
        handleSearch({
          keyword: e.data.data.datum.name,
          topK: e.data.data.datum.value          
        })
      })
    },
  };  
  useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true)
          setWordCloud([])

          const res = await fetchWordCloud(startDate, endDate, authToken)
          let cloudArr: any = []
          for (const [word, freq] of Object.entries(res.data.word_frequencies)) {
            cloudArr.push({
              name: word,
              value: freq,
              display: `${word}`          
            })
          }
          setWordCloud(cloudArr)
        } catch (e) {
          console.log('error while loading word cloud: ',e)
        }
        finally {
          setLoading(false)
        }
      }        
      fetchData()
  }, [startDate, endDate, authToken])
  
  return  <Spin spinning={loading}>
            {wordCloud.length > 0 && <WordCloud {...config}/>}
        </Spin>
};
export default WordCloudChart