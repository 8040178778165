import React from 'react'
import '../../css/WhitePaper.css'

const PreviousWhitePaperHeading: React.FC = () => {
    return (
        <div className='previous-whitepaper-heading'>
            <h1><strong>Explore Past AI Trends and Insights</strong> </h1>
                
        </div>
    )
}

export default PreviousWhitePaperHeading