import React from 'react'
import '../../css/WhitePaper.css'

import {CheckCircleFilled} from '@ant-design/icons';

const WhitePaperDesc: React.FC = () => {
    return (
        <div className='whitepaper-text-desc'>
            <h4>Dive into the AI evolution with our comprehensive guide to last year’s AI landscape, and look ahead to 2025’s potential.</h4>
            <br/>
            <h4>Through AI NewsHub's lens, this whitepaper explores:</h4>
                
            <h4><CheckCircleFilled style={{ color: '#15C748' }} /><strong > 2024 Recap:</strong> Key trends, regional nuances, and leading voices in AI.</h4>
            <h4><CheckCircleFilled style={{ color: '#15C748' }} /><strong> Quarterly Insights:</strong> In-depth analysis of each quarter's significant AI developments.</h4>
            <h4><CheckCircleFilled style={{ color: '#15C748' }} /><strong> Industry Impact:</strong> How various sectors are being transformed by AI.</h4>
            <h4><CheckCircleFilled style={{ color: '#15C748' }} /><strong> Future Outlook:</strong> Projections for 2025, focusing on emerging opportunities, particularly in Generative AI and Agentic workflows.</h4>
                
        </div>
    )
}

export default WhitePaperDesc