import { Col, Row } from 'antd';
import '../../css/Banner.css';
import '../../css/WhitePaper.css';
import '../../css/Common.css';
import React from 'react'
import { PaperDownloadButton } from './PaperDownloadButton';
import { useTheme } from "../../contexts/ThemeContext"
import { getOppThemeColor } from '../../utils/utils';
import { useAutheDataContext } from '../../contexts/AuthDataContext';


const WhitePaperHome: React.FC = () => {
const {theme} = useTheme()
const {user} = useAutheDataContext()
return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={4} xxl={4}/>
            <div></div>
            <Col xs={24} sm={24} md={24} lg={24} xl={4} xxl={4} className="whitepaper-photo" />
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <Row>
                    <Col span={24}>
                        <div className="whitepaper-big-text">
                            <h1 key="wpptext1" className="hero-font-content" style={{color: theme === 'black' ? '#ECD5C2' : 'white'}}>
                                AI Roundup 2024
                            </h1>
                            <h2 key="wpptext2" className="hero-font-content" style={{color: theme === 'black' ? '#ECD5C2' : 'white'}}>
                                Trends & Projections for 2025
                            </h2>
                        </div>
                    </Col>
                    {
                        (user === null) && (
                        <Col span={24}>
                            <PaperDownloadButton text={'Sign up and Download Whitepaper'}/>
                        </Col>)
                    }
                </Row>
            </Col>
            <Col xs={0} sm={0} md={0} lg={0} xl={4} xxl={4}/>
        </Row>
  );
}

export default WhitePaperHome;
