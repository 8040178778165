import { useEffect, useState } from "react"
import PieChart from "../pieChart/PieChart"
import { SearchCriteria } from "../../../types"
import { fetchPieData } from "../dataFetcher"
import { ChartTypes } from "../types"
import { useAutheDataContext } from '../../../contexts/AuthDataContext';
import { Spin } from "antd"
import {Dayjs} from 'dayjs'

interface PublisherStatsType {
    handleSearch: (options: SearchCriteria) => void
    startDate: Dayjs, 
    endDate: Dayjs,
    durationId: string
}

export const CategoriesStats: React.FC<PublisherStatsType> = ({handleSearch, 
                                                               startDate, 
                                                               endDate, 
                                                               durationId}) => {
    const {authToken} = useAutheDataContext()
    const [pieData, setPieData] = useState<any>(null)    
    const [loading, setLoading] = useState<any>(false)
    
    const handleDonutItemClick = (obj: any) => {
        handleSearch({
            category: obj.data.id,
            displayText: obj.data.name
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                setPieData(null)

                const res = await fetchPieData(ChartTypes.CATEGORIES, startDate, endDate, durationId, authToken)
                setPieData(res)
            } catch (err: any) {
                console.log('e: ', err)
            } finally {
                setLoading(false)    
            }
        }        
        fetchData()
    }, [startDate, endDate, authToken, durationId])

    return (
        <Spin spinning={loading}>
            { pieData && (
                    <>
                        <PieChart data = {pieData} 
                        handleDonutItemClick = {handleDonutItemClick} 
                        x={'count'} 
                        y={'name'} 
                        content={''}/>
                    </>
                 )
            }
        </Spin>
    )
}